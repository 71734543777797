// prefer default export if available
var preferDefault = function preferDefault(m) {
  return m && m.default || m;
};

exports.components = {
  "component---react-brazil-2020-mdx": function componentReactBrazil2020Mdx() {
    return import("./../../../../../react-brazil-2020.mdx"
    /* webpackChunkName: "component---react-brazil-2020-mdx" */
    );
  }
};